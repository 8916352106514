import React, { useContext, useEffect, useState } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { LoginPayload } from 'models'
import { AuthContext } from 'providers/AuthProvider'
import FieldError from 'components/FieldError'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const Login = () => {
    const [state, setState] = useState<LoginPayload>({
        login: '',
        password: '',
    })
    const { login, errors, clearErrors } = useContext(AuthContext)
    const [search] = useSearchParams()

    useEffect(() => {
        if (search.has('timeout')) {
            search.delete('timeout')
            toast.warning('Sesja wygasła. Proszę zalogować się ponownie.')
        }
    }, [])

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        clearErrors()
        login(state)
    }

    return (
        <div className="login-container">
            <div>
                <img src="/logo.png" alt="PGNiG" />
            </div>
            <div>
                <Form onSubmit={onSubmit}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Login"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            placeholder="Login"
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    login: e.target.value,
                                })
                            }
                            value={state.login}
                            isInvalid={!!errors?.login}
                        />
                        <FieldError errors={errors} name="login" />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingPassword"
                        label="Hasło"
                        className="mb-3"
                    >
                        <Form.Control
                            type="password"
                            placeholder="Hasło"
                            onChange={(e) =>
                                setState({ ...state, password: e.target.value })
                            }
                            value={state.password}
                            isInvalid={!!errors?.password}
                        />
                        <FieldError errors={errors} name="password" />
                    </FloatingLabel>
                    <Button
                        type="submit"
                        variant="primary"
                        size="lg"
                        className="w-100"
                    >
                        Zaloguj się
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default Login

import { User, UserPayload } from 'models/users'
import api from './api'

export const fetchUsers = async (): Promise<User[]> => {
    const resp = await api.get(`/users`)

    return resp.data
}

export const fetchUser = async (id: string): Promise<User> => {
    const resp = await api.get(`/users/${id}`)

    return resp.data
}

export const updateUser = async ({ id, groupId, regionId }: UserPayload) => {
    return await api.patch(`/users/${id}`, { groupId, regionId })
}

export const addUser = async (payload: UserPayload) => {
    return await api.post(`/users`, payload)
}

export const connect = async ({ sourceUserId, targetUserId }: { sourceUserId: string; targetUserId: string }) => {
    return await api.post(`/users/connect`, { sourceUserId, targetUserId })
}

import { updateUser } from 'api/users'
import { useFormik } from 'formik'
import { groupNames } from 'models/groups'
import { UserPayload } from 'models/users'
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import FieldError from '../FieldError'
import { fetchRegions } from 'api/regions'

interface Props {
    initialValues: UserPayload
}

const UserForm: React.FC<Props> = ({ initialValues }) => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { data: regions } = useQuery(['regions'], fetchRegions)
    const { isLoading, mutate, error } = useMutation<any, any, UserPayload>(updateUser)
    const errors = error?.response.data
    const { handleSubmit, handleChange, values } = useFormik<UserPayload>({
        initialValues,
        onSubmit: (values) => {
            mutate(values, {
                onSuccess: () => {
                    queryClient.invalidateQueries(['user', values.id])
                    toast.success('Użytkownik został zapisany.')
                    navigate(`/configuration/users`)
                },
            })
        },
    })

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Label>Imię</Form.Label>
                <Form.Control placeholder={initialValues.firstName} disabled />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Nazwisko</Form.Label>
                <Form.Control placeholder={initialValues.lastName} disabled />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Numer</Form.Label>
                <Form.Control placeholder={initialValues.id} disabled />
            </Form.Group>

            <Form.Group controlId="groupId" className="mb-3">
                <Form.Label>Grupa</Form.Label>
                <Form.Select onChange={handleChange} value={values.groupId} isInvalid={!!errors?.groupId}>
                    <option value="">-</option>
                    {Object.entries(groupNames).map((g) => (
                        <option value={g[0]} key={g[0]}>
                            {g[1]}
                        </option>
                    ))}
                </Form.Select>
                <FieldError errors={errors} name="groupId" />
            </Form.Group>
            <Form.Group controlId="regionId" className="mb-3">
                <Form.Label>Region</Form.Label>
                <Form.Select onChange={handleChange} value={values.regionId} isInvalid={!!errors?.regionId}>
                    <option value="">-</option>
                    {regions?.map((r) => (
                        <option value={r.id} key={r.id}>
                            {r.name}
                        </option>
                    ))}
                </Form.Select>
                <FieldError errors={errors} name="regionId" />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={isLoading}>
                Zapisz
            </Button>
        </Form>
    )
}

export default UserForm

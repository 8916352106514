import { useEffect, useRef } from 'react'

export const useGridHeaderIntersectionObserver = () => {
    const headerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const observer = new IntersectionObserver(([e]) => {
            if (e.isIntersecting) {
                e.target.classList.remove('is-pinned')
                document.querySelectorAll('.btn-shift').forEach((btn) => {
                    btn.classList.remove('is-pinned')
                })
            } else {
                e.target.classList.add('is-pinned')
                document.querySelectorAll('.btn-shift').forEach((btn) => {
                    btn.classList.add('is-pinned')
                })
            }

            const gridWidth = document.querySelector('.schedules-grid')!.scrollWidth
            document.querySelector('.shifts-bar')?.setAttribute('style', `width: ${gridWidth}`)
        })

        if (headerRef.current) observer.observe(headerRef.current)

        return () => {
            if (headerRef.current) observer.unobserve(headerRef.current)
        }
    }, [headerRef])

    return headerRef
}

export default useGridHeaderIntersectionObserver

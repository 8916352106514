import { useFormik } from 'formik'
import { groupNames } from 'models/groups'
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import FieldError from '../FieldError'
import { UserPayload } from 'models/users'
import { useMutation, useQueryClient } from 'react-query'
import { addUser } from 'api/users'
import { Contract, contractNames } from 'models'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const AddUserForm = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { isLoading, mutate, error } = useMutation<any, any, UserPayload>(addUser)
    const errors = error?.response.data
    const { handleChange, handleSubmit, values, setFieldValue } = useFormik<UserPayload>({
        initialValues: {
            firstName: '',
            lastName: '',
            groupId: 0,
            contract: undefined,
            description: '',
            regionId: undefined,
        },
        onSubmit: (values) => {
            mutate(values, {
                onSuccess: () => {
                    queryClient.invalidateQueries(['users'])
                    toast.success('Użytkownik został dodany.')
                    navigate(`/configuration/users`)
                },
            })
        },
    })

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>Imię</Form.Label>
                <Form.Control onChange={handleChange} value={values.firstName} isInvalid={!!errors?.firstName} />
                <FieldError errors={errors} name="firstName" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="lastName">
                <Form.Label>Nazwisko</Form.Label>
                <Form.Control onChange={handleChange} value={values.lastName} isInvalid={!!errors?.lastName} />
                <FieldError errors={errors} name="lastName" />
            </Form.Group>
            <Form.Group controlId="groupId" className="mb-3">
                <Form.Label>Grupa</Form.Label>
                <Form.Select onChange={handleChange} value={values.groupId} isInvalid={!!errors?.groupId}>
                    <option value="">-</option>
                    {Object.entries(groupNames).map((g) => (
                        <option value={g[0]} key={g[0]}>
                            {g[1]}
                        </option>
                    ))}
                </Form.Select>
                <FieldError errors={errors} name="groupId" />
            </Form.Group>

            <div className="mb-3">
                <Form.Check
                    inline
                    label={contractNames[Contract.UoP]}
                    name="contract"
                    type="radio"
                    checked={values.contract === Contract.UoP}
                    id="contract-2"
                    onChange={() => setFieldValue('contract', Contract.UoP)}
                    value={Contract.UoP}
                />
                <Form.Check
                    inline
                    label={contractNames[Contract.UZ]}
                    name="contract"
                    type="radio"
                    checked={values.contract === Contract.UZ}
                    id="contract-3"
                    onChange={() => setFieldValue('contract', Contract.UZ)}
                    value={Contract.UZ}
                />
                <FieldError errors={errors} name="contract" />
            </div>

            <Form.Group className="mb-3" controlId="description">
                <Form.Label>Uwagi</Form.Label>
                <Form.Control
                    as="textarea"
                    onChange={handleChange}
                    value={values.description}
                    isInvalid={!!errors?.description}
                />
                <FieldError errors={errors} name="description" />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={isLoading}>
                Zapisz
            </Button>
        </Form>
    )
}

export default AddUserForm

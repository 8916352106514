import React, { useContext, useState } from 'react'
import { Col, Nav, Navbar, Row } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../providers/AuthProvider'
import { RiLogoutCircleRLine } from 'react-icons/ri'
import { BsPrinterFill } from 'react-icons/bs'
import { toast } from 'react-toastify'
import { canAccessConfiguration, canAccessLists, canAccessSchedules, isReadOnly } from 'permissions'

const Main = () => {
    const { logout } = useContext(AuthContext)
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [expanded, setExpanded] = useState(false)

    const onLogout = () => {
        logout()
        toast.success('Pomyślnie wylogowano')
        navigate('/')
    }

    const hideNavbar = () => {
        setExpanded(false)
    }

    return (
        <>
            <Navbar
                expand="lg"
                id="navigation"
                className="mb-3"
                collapseOnSelect={true}
                onToggle={() => {
                    setExpanded(!expanded)
                }}
                expanded={expanded}
                style={{ boxShadow: '0 0.15rem 0.5rem rgba(0, 0, 0, 0.15)' }}
            >
                <Container fluid>
                    <Navbar.Brand
                        as={Link}
                        to="/"
                        className="fs-6 fw-light d-flex align-items-center gap-2"
                        onClick={hideNavbar}
                    >
                        <img src="/logo-horizontal.png" alt="PGNiG" height="35" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="main-nav" />
                    <Navbar.Collapse id="main-nav">
                        <Nav className="me-auto">
                            {canAccessSchedules() && (
                                <>
                                    <Nav.Link as={Link} to="/" active={pathname === '/'} onClick={hideNavbar}>
                                        Planowanie
                                    </Nav.Link>
                                    <Nav.Link
                                        as={Link}
                                        to="/user-schedule"
                                        active={pathname === '/user-schedule'}
                                        onClick={hideNavbar}
                                    >
                                        Grafik
                                    </Nav.Link>
                                </>
                            )}
                            {!isReadOnly() && (
                                <Nav.Link
                                    as={Link}
                                    to="/calendar"
                                    active={pathname.startsWith('/calendar')}
                                    onClick={hideNavbar}
                                >
                                    Mój kalendarz
                                </Nav.Link>
                            )}

                            {canAccessLists() && (
                                <Nav.Link
                                    as={Link}
                                    to="/lists"
                                    active={pathname.startsWith('/lists')}
                                    onClick={hideNavbar}
                                >
                                    Listy
                                </Nav.Link>
                            )}
                            {canAccessConfiguration() && (
                                <Nav.Link
                                    as={Link}
                                    to="/configuration"
                                    active={pathname.startsWith('/configuration')}
                                    onClick={hideNavbar}
                                >
                                    Konfiguracja
                                </Nav.Link>
                            )}
                        </Nav>
                        <Nav>
                            <Nav.Link onClick={() => window.print()}>
                                <BsPrinterFill size="1.25rem" />
                            </Nav.Link>
                            <Nav.Link onClick={onLogout}>
                                <RiLogoutCircleRLine size="1.5rem" /> Wyloguj
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container fluid>
                <Row>
                    <Col>
                        <Outlet />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Main

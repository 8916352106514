import { download, fetchLists } from 'api/lists'
import { confirmClose } from 'components/Lists/PromptClose'
import { confirmFinish } from 'components/Lists/PromptFinish'
import Spinner from 'components/Spinner'
import { isManagement, isReadOnly } from 'permissions'
import React from 'react'
import { Button, Dropdown, Table } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useAuth } from '../providers/AuthProvider'
import { toast } from 'react-toastify'

export const Lists = () => {
    const { profile } = useAuth()
    const { isLoading, data } = useQuery(['lists'], fetchLists)
    const isMgmt = isManagement()
    const isRdOnly = isReadOnly()

    const handleDownload = async (id: number, type: string) => {
        const toastId = toast.info('Pobieranie pliku...', { autoClose: false })
        const downloadData = await download(id, type)

        if (downloadData?.data) {
            const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${downloadData.data}`
            const downloadLink = document.createElement('a')
            downloadLink.href = linkSource
            downloadLink.download = 'export.xlsx'
            downloadLink.click()
            toast.dismiss(toastId)
        }
    }

    if (isLoading) return <Spinner />

    return (
        <Table>
            <thead>
                <tr>
                    <th>Rok</th>
                    <th>Miesiąc</th>
                    <th>Region</th>
                    <th>Data zamknięcia</th>
                    <th>Użytkownik zamykający</th>
                    <th>Data zakończenia</th>
                    <th>Użytkownik kończący</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data?.map((list) => (
                    <tr key={list.id}>
                        <td>{list.year}</td>
                        <td>{list.month}</td>
                        <td>{list.region.name}</td>
                        <td>{list.closed_at ?? '-'}</td>
                        <td>
                            {list.closing_user ? `${list.closing_user.firstName} ${list.closing_user.lastName}` : '-'}
                        </td>
                        <td>{list.final_closed_at ?? '-'}</td>
                        <td>
                            {list.final_closing_user
                                ? `${list.final_closing_user.firstName} ${list.final_closing_user.lastName}`
                                : '-'}
                        </td>
                        <td>
                            {profile?.regionId === list.region_id && (
                                <>
                                    {isMgmt && !list.closed_at && (
                                        <Button variant="warning" size="sm" onClick={() => confirmClose(list.id)}>
                                            Potwierdzenie zamknięcia
                                        </Button>
                                    )}

                                    {isMgmt && list.closed_at && !list.final_closed_at && (
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => confirmFinish(list.id, list.year, list.month)}
                                        >
                                            Ostateczne zamknięcie
                                        </Button>
                                    )}

                                    {(isMgmt || isRdOnly) && list.final_closed_at && (
                                        <Dropdown>
                                            <Dropdown.Toggle size="sm">Pobierz</Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleDownload(list.id, 'export')}>
                                                    Pobierz XLSX
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => handleDownload(list.id, 'salary-supplements')}
                                                >
                                                    Pobierz dodatki
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )}
                                </>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default Lists

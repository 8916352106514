import { Day } from 'models'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import React, { useEffect } from 'react'
import { generateMonthDays, isToday } from 'utils'
import useGridHeaderIntersectionObserver from 'hooks/useGridHeaderIntersectionObserver'

type Props = {
    days: Day[]
    setDays: (days: Day[]) => void
}

const GridHeader: React.FC<Props> = ({ days, setDays }) => {
    const {
        state: { year, month, quarter },
    } = useSchedulesContext()

    useEffect(() => {
        setDays(generateMonthDays(year, month))
    }, [month, quarter])

    const headerRef = useGridHeaderIntersectionObserver()

    return (
        <>
            <div className="header row-span-2" ref={headerRef}>
                Dzień
            </div>
            <div className="header row-3 sticky-top">Posterunek</div>

            {days.map((d) => {
                const boundary = isToday(year, month, d.number) ? 'day-boundary' : ''

                return (
                    <React.Fragment key={d.number}>
                        <div
                            className={`header sticky-top row-span-n d-flex flex-column ${boundary}`}
                            style={{ '--row-span': 3 } as React.CSSProperties}
                        >
                            <span>{d.number}</span>
                            <span>{d.weekday}</span>
                        </div>
                    </React.Fragment>
                )
            })}
        </>
    )
}

export default React.memo(GridHeader)

import { fetchStationActions } from 'api/stations'
import { AxiosResponse } from 'axios'
import FieldError from 'components/FieldError'
import { useFormik } from 'formik'
import { Errors, Station, StationPayload } from 'models'
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

type Props = {
    initialValues: Station
    mutation: (data: StationPayload) => Promise<AxiosResponse<Errors, any>>
    locationId?: number
}

const StationForm: React.FC<Props> = ({ initialValues, mutation, locationId }) => {
    const { isLoading, mutate, error } = useMutation<any, any, StationPayload>(mutation)
    const { data: actions } = useQuery(['stationActions'], fetchStationActions)
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const errors = error?.response.data

    const { handleSubmit, handleChange, values } = useFormik<Station>({
        initialValues,
        onSubmit: (values) => {
            mutate(
                { station: values, locationId },
                {
                    onSuccess: ({ data }: any) => {
                        queryClient.setQueryData(['station', data.id], data)
                        toast.success('Posterunek został zapisany.')
                        navigate(`/configuration/stations`)
                    },
                }
            )
        },
    })

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name" className="mb-3">
                <Form.Label>Nazwa</Form.Label>
                <Form.Control type="text" onChange={handleChange} value={values.name} isInvalid={!!errors?.name} />
                <FieldError errors={errors} name="name" />
            </Form.Group>
            <Form.Group controlId="description" className="mb-3">
                <Form.Label>Opis</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={handleChange}
                    value={values.description}
                    isInvalid={!!errors?.description}
                />
                <FieldError errors={errors} name="description" />
            </Form.Group>
            <Form.Group controlId="station_action_id" className="mb-3">
                <Form.Label>Czynność</Form.Label>
                <Form.Select
                    onChange={handleChange}
                    value={values.station_action_id ?? 0}
                    isInvalid={!!errors?.station_action_id}
                >
                    <option value="">-</option>
                    {actions?.map((a) => (
                        <option value={a.id} key={a.id}>
                            {a.long_name}
                        </option>
                    ))}
                </Form.Select>
                <FieldError errors={errors} name="station_action_id" />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={isLoading}>
                Zapisz
            </Button>
        </Form>
    )
}

export default StationForm

import { List } from 'models/lists'
import api from './api'

export const fetchLists = async () => {
    const resp = await api.get<List[]>(`/lists`)

    return resp.data
}

export const close = async (id: number) => {
    const resp = await api.post(`/lists/${id}/close`)

    return resp.data
}

export const finish = async (id: number) => {
    const resp = await api.post(`/lists/${id}/finish`)

    return resp.data
}

export const download = async (id: number, type: string) => {
    const resp = await api.get(`/lists/${id}/download/${type}`)

    return resp.data
}

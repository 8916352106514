import LocationSelect from 'components/LocationSelect'
import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetchUsers } from 'api/users'
import Spinner from 'components/Spinner'
import { fetchUserAssignments, setAssignment } from 'api/stationUsers'
import { Form, Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { fetchStations } from 'api/stations'
import { useDebounce } from 'usehooks-ts'

interface SwitchProps {
    stationId?: number
    locationId: number
    userId: string
    checked?: boolean
}

const Switch: React.FC<SwitchProps> = ({ stationId, userId, locationId, checked }) => {
    const queryClient = useQueryClient()

    const { mutate } = useMutation(setAssignment, {
        onSuccess: () => {
            queryClient.invalidateQueries(['assignments', locationId])
        },
        onError: () => {
            toast.error('Wystąpił błąd')
        },
    })
    return (
        <Form.Check
            type="switch"
            checked={checked}
            onChange={(e) =>
                mutate({
                    stationId,
                    userId,
                    locationId,
                    checked: e.target.checked,
                })
            }
        />
    )
}

export const LocationUser = () => {
    const [locationId, setLocationId] = useState<number>()
    const [value, setValue] = useState('')
    const search = useDebounce<string>(value, 500)

    const { isLoading: isLoadingUsers, data: users } = useQuery(['users'], fetchUsers)
    const { isLoading: isLoadingStations, data: stations } = useQuery(
        ['stations', locationId],
        () => fetchStations(locationId!),
        { enabled: !!locationId }
    )
    const { isLoading: isLoadingJoint, data: assignments } = useQuery(
        ['assignments', locationId],
        () => fetchUserAssignments(locationId!),
        { enabled: !!locationId }
    )

    if (isLoadingUsers || isLoadingStations || isLoadingJoint) return <Spinner />

    const filteredUsers = search.length
        ? users?.filter((d) => `${d.firstName} ${d.lastName}`.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        : users

    return (
        <>
            <LocationSelect locationId={locationId} setLocationId={setLocationId} />
            {locationId && (
                <div className="mt-3">
                    <div className="mb-3">
                        <Form.Control
                            type="search"
                            placeholder="Wyszukaj"
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                        />
                    </div>
                    <Table>
                        <thead className="bg-white sticky-top">
                            <tr>
                                <th>Wszystkie</th>
                                <th>Użytkownik</th>
                                <th>Numer</th>

                                {stations?.map((s) => (
                                    <th key={s.id}>{s.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers?.map((u) => (
                                <tr key={u.id}>
                                    <td>
                                        <Switch userId={u.id!} locationId={locationId} />
                                    </td>
                                    <td className="bg-white sticky-left">
                                        {u.lastName} {u.firstName}
                                    </td>
                                    <td>
                                        {u.number} / {u.contract}
                                    </td>

                                    {stations?.map((s) => (
                                        <td key={s.id}>
                                            <Switch
                                                stationId={s.id!}
                                                userId={u.id!}
                                                locationId={locationId}
                                                checked={assignments!.some(
                                                    (a) => a.stationId === s.id && a.userId === u.id
                                                )}
                                            />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </>
    )
}

export default LocationUser

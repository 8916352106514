import { isToday, makeDate } from 'utils'
import dayjs from 'dayjs'
import tinycolor from 'tinycolor2'
import React from 'react'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import { UserWorkItem } from 'models/work'

export const WorkCell = ({ day, work }: { day: number; work: UserWorkItem[] | undefined }) => {
    const {
        state: { year, month },
    } = useSchedulesContext()
    const date = makeDate(year, month, day)
    const boundary = isToday(year, month, day) ? 'day-boundary' : ''
    const weekday = dayjs(date).isoWeekday()
    const weekend = weekday === 6 || weekday === 7

    return (
        <div className={`cell work ${weekend ? 'weekend' : ''} ${boundary}`} key={day}>
            {work?.map((sh) => {
                return (
                    <div
                        style={{
                            backgroundColor: sh.color,
                            color: tinycolor(sh.color).isDark() ? 'white' : 'black',
                        }}
                        key={sh.id}
                        className={`user-select-none`}
                    >
                        <span className="fw-bold fs-6">{sh.name}</span>
                        <span>
                            {dayjs(`${date} ${sh.start}`).format('H:mm')} - {dayjs(`${date} ${sh.end}`).format('H:mm')}
                        </span>
                    </div>
                )
            })}
        </div>
    )
}

export default WorkCell

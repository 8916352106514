import { fetchUser } from 'api/users'
import Spinner from 'components/Spinner'
import UserForm from 'components/Configuration/UserForm'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import List from 'components/Configuration/SalarySupplements/List'

export const EditUser = () => {
    const params = useParams()
    const id = params.id!
    const { isLoading, data } = useQuery(['user', id], () => fetchUser(id))

    if (isLoading) return <Spinner />

    return (
        <Row>
            <Col md={4}>
                <UserForm initialValues={data!} />
            </Col>
            <Col md={8}>
                <List />
            </Col>
        </Row>
    )
}

export default EditUser

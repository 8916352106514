import DateBar from 'components/Schedules/DateBar'
import React, { useReducer, useState } from 'react'
import { Actions, reducer, State } from 'reducers/schedulesReducer'
import dayjs from 'dayjs'
import { Contract, Day, SchedulesLayout } from 'models'
import SchedulesContext from 'providers/SchedulesProvider'
import { Form } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { fetchUniqueUser } from 'api/work'
import GridHeader from 'components/UserSchedule/GridHeader'
import GridBody from 'components/UserSchedule/GridBody'

export const UserSchedule = () => {
    const initialState: State = {
        month: dayjs().month() + 1,
        quarter: dayjs().quarter(),
        year: dayjs().year(),
        availableContract: Contract.All,
        layout: SchedulesLayout.Stations,
    }
    const [state, dispatch] = useReducer(reducer, initialState)
    const { userId, year, month } = state
    const { data } = useQuery(['uniqueUsers', year, month], () => fetchUniqueUser({ year, month }))
    const [days, setDays] = useState<Day[]>([])

    return (
        <SchedulesContext.Provider value={{ state, dispatch }}>
            <div className="d-flex justify-content-between align-items-center flex-wrap gap-3" id="search-bar">
                <DateBar />
                <div>
                    <Form.Select
                        value={userId}
                        onChange={(e) => dispatch({ type: Actions.SetUser, userId: e.target.value })}
                    >
                        <option value="">Wybierz użytkownika</option>
                        {data?.map((u) => (
                            <option value={u.id} key={u.id}>
                                {u.lastName} {u.firstName} ({u.id})
                            </option>
                        ))}
                    </Form.Select>
                </div>
            </div>
            <div className="mt-3 schedules-grid" style={{ ['--grid-columns' as string]: days.length + 1 }}>
                <GridHeader days={days} setDays={setDays} />
                <GridBody days={days} />
            </div>
        </SchedulesContext.Provider>
    )
}

export default UserSchedule

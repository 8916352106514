import React from 'react'

type Props = {
    remaining: {
        month: { [key: string]: number }
        quarter: { [key: string]: number }
    }
    userId: string
}
const MonthRemaining: React.FC<Props> = ({ remaining, userId }) => {
    return (
        <div className="cell grid-center bg-gray-500 fw-bolder">
            {remaining.month[userId] && (
                <span className="d-flex w-100 justify-content-between">
                    <span className="fw-normal">M</span> {remaining.month[userId] ?? ''}
                </span>
            )}
        </div>
    )
}

export default MonthRemaining

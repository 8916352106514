import dayjs from 'dayjs'
import { Day } from './models'

export const makeArray = (count: number) => new Array(count).fill(1)

export const makeDate = (year: any, month: any, day: any) =>
    `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`

export const isToday = (year: number, month: number, day: number) => dayjs(`${year}-${month}-${day}`).isToday()

export const getDBWeekday = (date: string) => {
    const weekday = dayjs(date).isoWeekday()

    return weekday === 7 ? 0 : weekday
}

export const formatHourMinutes = (time: string) => dayjs(`2000-01-01 ${time}`).format('H:mm')

export const generateMonthDays = (year: number, month: number) => {
    const start = dayjs(`${year}-${month}-01`)
    const end = start.endOf('month')

    const data: Day[] = []
    let date = start
    do {
        data.push({
            number: +date.format('D'),
            weekday: date.format('ddd'),
        })
        date = date.add(1, 'day')
    } while (date < end)

    return data
}

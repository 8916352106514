import Spinner from 'components/Spinner'
import { useSchedulesContext } from 'providers/SchedulesProvider'
import React from 'react'
import { useQuery } from 'react-query'
import { fetchUserWork } from 'api/work'
import { makeArray, makeDate } from 'utils'
import { Day } from 'models'
import WorkCell from './WorkCell'

const GridBody = ({ days }: { days: Day[] }) => {
    const {
        state: { year, month, userId },
    } = useSchedulesContext()

    const { isLoading, data: work } = useQuery(
        ['userWork', userId, year, month],
        () =>
            fetchUserWork({
                userId: userId!,
                year,
                month,
            }),
        { enabled: !!userId }
    )

    if (isLoading) return <Spinner />
    if (!work) return null

    return (
        <>
            {Object.keys(work!).map((location) => {
                return (
                    <React.Fragment key={location}>
                        {Object.keys(work![location]).map((station) => {
                            return (
                                <>
                                    <div
                                        className="col-1 row-span-n cell station-name flex-column text-center"
                                        key={station}
                                    >
                                        <span className="text-nowrap">{location}</span>
                                        <small className="fw-light text-nowrap">{station}</small>
                                    </div>

                                    {makeArray(days.length).map((_, i) => {
                                        const day = i + 1
                                        const date = makeDate(year, month, day)

                                        return <WorkCell key={i} day={day} work={work![location][station][date]} />
                                    })}
                                </>
                            )
                        })}
                    </React.Fragment>
                )
            })}
        </>
    )
}

export default GridBody

import {Button} from 'react-bootstrap'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {User} from 'models/users'
import {connect} from 'api/users'

export const confirmConnect = (source: User, target: User, clear: () => void) => {
    toast(<PromptConnect source={source} target={target} clear={clear}/>, {
        position: 'top-center',
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
    })
}

export const PromptConnect = ({source, target, clear}: { source: User, target: User, clear: () => void }) => {
    const qc = useQueryClient()
    const {isLoading, mutate} = useMutation(
            connect,
            {
                onSuccess() {
                    clear()
                    toast.dismiss()
                    qc.invalidateQueries(['users'])
                    qc.invalidateQueries(['groups'])
                    toast.success('Dane użytkownika zostały przepisane')
                },
                onError() {
                    toast.error('Wystąpił błąd')
                },
            },
    )
    return (
            <>
                <div>
                    Czy na pewno chcesz przepisać dane
                    użytkownika <strong>{source.firstName} {source.lastName}</strong> {' '}
                    na użytkownika <strong>{target.firstName} {target.lastName}</strong>?
                </div>
                <div className="d-flex justify-content-between mt-2">
                    <Button variant="danger" disabled={isLoading} onClick={() => toast.dismiss()}>Nie</Button>
                    <Button
                            variant="success"
                            disabled={isLoading}
                            onClick={() => mutate({sourceUserId: source.id, targetUserId: target.id})}
                    >Tak</Button>
                </div>
            </>
    )
}

export default PromptConnect
import { deleteSupplement, fetchSupplements } from 'api/salarySupplements'
import Spinner from 'components/Spinner'
import dayjs from 'dayjs'
import { Button, Table } from 'react-bootstrap'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { BsArrowRepeat } from 'react-icons/bs'

const List = () => {
    const id = useParams().id!
    const queryClient = useQueryClient()
    const { isLoading, data } = useQuery(['salary-supplements', id], () => fetchSupplements(id))
    const { isLoading: isMutating, mutate } = useMutation(deleteSupplement, {
        onSuccess: () => {
            void queryClient.invalidateQueries(['salary-supplements', id])
            toast.success('Dodatek został usunięty')
        },
    })

    if (isLoading) return <Spinner />

    return (
        <>
            <Link to={`/configuration/users/${id}/salary-supplement`} className="btn btn-secondary mb-2">
                Dodaj
            </Link>

            {!data?.length ? (
                <div>Brak dodatków</div>
            ) : (
                <Table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Rok</th>
                            <th>Miesiąc</th>
                            <th>Kwota</th>
                            <th>Data utworzenia</th>
                            <th>Opis</th>
                            <th>Obiekt</th>
                            <th>Posterunek</th>
                            <th>Autor</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((s) => (
                            <tr key={s.id}>
                                <td>{!!s.cyclic && <BsArrowRepeat title="Dodatek cykliczny" />}</td>
                                <td>{s.year}</td>
                                <td>{s.month}</td>
                                <td>{s.amount}</td>
                                <td>{dayjs(s.created_at).format('YYYY-MM-DD HH:mm')}</td>
                                <td>{s.description}</td>
                                <td>{s.location_name ?? '-'}</td>
                                <td>{s.station_name ?? '-'}</td>
                                <td>
                                    {s.creating_user_name}
                                    <br />
                                    <span className="text-muted fw-light">{s.creating_user_id}</span>
                                </td>
                                <td>
                                    {s.can_delete && (
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            disabled={isMutating}
                                            onClick={() => mutate(s.id!)}
                                        >
                                            <FaTrash />
                                        </Button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </>
    )
}

export default List

import {fetchGroups} from 'api/groups'
import {fetchUsers} from 'api/users'
import Icon from 'components/Icon'
import Spinner from 'components/Spinner'
import {groupNames} from 'models/groups'
import {useState} from 'react'
import {Alert, Badge, Button, Form, Table} from 'react-bootstrap'
import {FaRegEdit} from 'react-icons/fa'
import {useQuery} from 'react-query'
import {Link} from 'react-router-dom'
import {useDebounce} from 'usehooks-ts'
import {FiClock} from 'react-icons/fi'
import {makeDate} from 'utils'
import dayjs from 'dayjs'
import {BiLink, BiTimer} from 'react-icons/bi'
import {User} from 'models/users'
import {confirmConnect} from 'components/Configuration/Users/PromptConnect'

export const Users = () => {
    const [value, setValue] = useState('')
    const [connectUserId, setConnectUserId] = useState<string>()
    const search = useDebounce<string>(value, 500)
    const {isLoading, data} = useQuery(['users'], fetchUsers)
    const {isLoading: ilGroups, data: groups} = useQuery(['groups'], fetchGroups)

    if (isLoading || ilGroups) return <Spinner/>

    const users = search.length
            ? data?.filter(
                    (d) =>
                            `${d.firstName} ${d.lastName}`.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                            (groupNames[groups![d.id]] ?? '').toLocaleLowerCase().includes(search.toLocaleLowerCase()),
            )
            : data

    const connectingUser = connectUserId ? data?.find((u) => u.id === connectUserId) : null

    function toggleConnectUser(id: string) {
        if (connectUserId === id) {
            setConnectUserId(undefined)
        } else {
            setConnectUserId(id)
        }
    }

    function connectUser(target: User) {
        confirmConnect(connectingUser!, target, () => setConnectUserId(undefined))
    }

    return (
            <>
                <div className="d-flex justify-content-between align-items-center gap-2">
                    <Form.Control
                            type="search"
                            placeholder="Wyszukaj"
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                    />
                    <Link to="/configuration/users/add" className="btn btn-primary text-nowrap">
                        Dodaj tymczasowego
                    </Link>
                </div>

                {connectUserId &&
                        <Alert variant="warning" className="my-2 d-flex justify-content-between align-items-center">
                            <div>
                                Wybierz użytkownika na którego zostaną przepisane dane
                                użytkownika <strong>{connectingUser?.firstName} {connectingUser?.lastName}</strong>
                            </div>
                            <Button
                                    variant="warning"
                                    size="sm"
                                    onClick={() => setConnectUserId(undefined)}
                            >Anuluj</Button>
                        </Alert>}

                <Table className="mt-2">
                    <thead className="bg-white sticky-top">
                    <tr>
                        <th>Użytkownik</th>
                        <th>Kod</th>
                        <th>Uprawnienia</th>
                        <th>Dodatki</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users?.map((u, i) => (
                            <tr key={`${i} ${u.id}`}>
                                <td>
                                    {u.temporary &&
                                            <span title="Użytkownik tymczasowy"><Icon name={BiTimer}/>&nbsp;</span>}

                                    {!u.temporary && connectUserId &&
                                            <Button
                                                    size="sm"
                                                    className="me-2"
                                                    onClick={() => connectUser(u)}
                                            ><Icon name={BiLink}/></Button>}

                                    {u.lastName} {u.firstName}
                                </td>
                                <td>
                                    {u.number} / {u.contract}
                                </td>
                                <td>{groups![u.id] ? groupNames[groups![u.id]] : '-'}</td>
                                <td>
                                    <div className="d-flex gap-1">
                                        {u.salarySupplements?.map((s, i) => (
                                                <Badge key={i} bg="light" text="dark" className="mr-1">
                                                    {dayjs(makeDate(s.year, s.month, 1)).format('MM.YYYY')}
                                                </Badge>
                                        ))}
                                    </div>
                                </td>
                                <td className="text-end">
                                    {u.temporary &&
                                            <Button
                                                    size="sm"
                                                    className="text-primary"
                                                    style={{backgroundColor: '#fff', border: 'none'}}
                                                    onClick={() => toggleConnectUser(u.id)}
                                                    title="Przepisz użytkownika"
                                            ><Icon name={BiLink}/></Button>}
                                    <Link to={`/configuration/users/${u.id}`}>
                                        <Icon name={FaRegEdit}/>
                                    </Link>{' '}
                                    <Link to={`/configuration/users/temp-groups/${u.id}`}>
                                        <Icon name={FiClock}/>
                                    </Link>
                                </td>
                            </tr>
                    ))}
                    </tbody>
                </Table>
            </>
    )
}

export default Users

import { addShift } from 'api/shifts'
import ShiftForm from 'components/Configuration/ShiftForm'
import { Contract, ShiftPayload, ShiftTimeType } from 'models'

const initialValues: ShiftPayload = {
    code: '',
    color: '',
    end: '',
    name: '',
    start: '',
    type: 0,
    count_work_time: true,
    contract: Contract.All,
    time_type: ShiftTimeType.Day,
    is_training: false,
    show_as_in_report: null,
    to_client_report: false,
}

export const AddShift = () => {
    return <ShiftForm initialValues={initialValues} mutation={addShift} />
}

export default AddShift

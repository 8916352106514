import { Errors, Shift, ShiftPayload } from 'models'
import api from './api'

export const fetchShifts = async () => {
    const resp = await api.get<Shift[]>(`/shifts`)

    return resp.data
}

export const fetchShift = async (id: number) => {
    const resp = await api.get<Shift>(`/shifts/${id}`)

    return resp.data
}

export const addShift = async (data: ShiftPayload) => {
    return await api.post<Errors>(`/shifts`, data)
}

export const editShift = async (data: ShiftPayload) => {
    const {
        name,
        code,
        color,
        type,
        start,
        end,
        count_work_time,
        contract,
        time_type,
        is_training,
        show_as_in_report,
        to_client_report,
    } = data
    return await api.patch<Errors>(`/shifts/${data.id}`, {
        name,
        code,
        color,
        type,
        start,
        end,
        count_work_time,
        contract,
        time_type,
        is_training,
        show_as_in_report,
        to_client_report,
    })
}

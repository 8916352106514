export enum ShiftType {
    Shift = 1,
    Free,
    Vacation,
    SickLeave,
    Training,
}

export enum ShiftTimeType {
    Day = 1,
    Night,
}

export enum Contract {
    All = 'ALL',
    UoP = 'P',
    UZ = 'Z',
}

export enum SchedulesLayout {
    Stations,
    Users,
}

export enum HourSummary {
    MonthStation = 'month-station',
    UserStation = 'user-station',
    Station = 'station',
    Day = 'day',
    MonthRemaining = 'month-remaining',
    MonthUser = 'month-user',
    User = 'user',
}

export const shiftTypeNames = {
    [ShiftType.Shift]: 'Zmiana',
    [ShiftType.Free]: 'Wolne',
    [ShiftType.Vacation]: 'Urlop',
    [ShiftType.SickLeave]: 'Zwolnienie',
    [ShiftType.Training]: 'Szkolenie',
}

export const shiftTimeTypeNames = {
    [ShiftTimeType.Day]: 'D',
    [ShiftTimeType.Night]: 'N',
}

export const contractNames = {
    [Contract.All]: 'Wszystkie',
    [Contract.UoP]: 'Umowa o pracę',
    [Contract.UZ]: 'Umowa zlecenie',
}

export interface ShiftPayload {
    id?: number
    name: string
    start: string
    end: string
    code: string
    color: string
    time_type: ShiftTimeType
    count_work_time: boolean
    type: ShiftType
    contract: Contract
    presence?: number | null
    to_client_report: boolean
    show_as_in_report: number | null
    is_training: boolean
}

export interface Shift extends ShiftPayload {
    hours: number
    stations?: Station[]
}

export interface Location {
    id?: number
    name: string
    customer_name: string
    address: string
    post_code: string
    city: string
    stations?: Station[]
    is_active?: boolean
    region_id?: number
}

export interface ActivatePayload {
    id: number
    is_active: boolean
}

export interface Station {
    id?: number
    name: string
    description: string
    shifts?: Shift[]
    location_id?: number
    station_action_id?: number | null
    is_active?: boolean
}

export interface StationPayload {
    station: Station
    locationId?: number
    stationActionId?: number
}

export interface ShiftAssignment {
    shiftId: number
    stationId: number
    checked: boolean
}

export interface UserAssignment {
    locationId?: number
    stationId?: number
    userId: string
    checked: boolean
}

export interface Day {
    number: number
    weekday: string
}

export interface ValidationResult {
    user: string
    dates: string
    message: string
    highlight?: boolean
}

export interface StationWeekdayHour {
    id: number
    weekday: number
    hours: number
}

export interface StationAction {
    id: number
    long_name: string
    short_name: string
    abbreviation: string
    is_active: number
    created_at: string | null
    updated_at: string | null
}

export type Errors = {
    [key: string]: string[]
}

export type LoginPayload = {
    login: string
    password: string
}
